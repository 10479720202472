import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"



import {LanguageContext} from './LanguageProvider'
import Navbar from './navbar'
import Header from './header'
import Footer from './footer'


export default function ChildLayout(props){



  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`)













    let [context, setContext] =useState("en")
        
    useEffect(() => {
        if (localStorage.getItem('language')) {
          setContext(localStorage.getItem('language'))
        }
      }, [])

     

let change=(event)=>{
    event.preventDefault()
     setContext(event.target.name)
     localStorage.setItem('language', event.target.name);
}


let x =(<>
<Helmet>
<title>{data.site.siteMetadata.title}</title>
</Helmet>
    <LanguageContext.Provider value ={context}>
    <Navbar change = {change}></Navbar>
    <Header mainHeading ={props.mainHeading[context].header} ></Header>
    {props.children}
    <Footer></Footer>
    </LanguageContext.Provider>
    </>
 )


 return x
       
}