import React, {useContext} from 'react'
import {LanguageContext} from './LanguageProvider'
import ReactMarkdown from 'react-markdown'
import { StaticImage} from "gatsby-plugin-image"
import { GatsbyImage } from 'gatsby-plugin-image'




export default function Header(props) {
  const context = useContext(LanguageContext)

  let x = (<header className={"header"}>
      <StaticImage className ={"headerImage"} src='../images/header1.jpg' placeholder="tracedSVG" />
      <div className ="headDiv">
          <ReactMarkdown>{props.mainHeading}</ReactMarkdown>
          <p></p>
      </div>
  </header>
  )
  return x

}



